import React, { Component } from 'react'
import { Link } from 'gatsby'

class PageHeader extends Component {
  render() {
  	let { title, background_image, schedule_a_call } = this.props
    return (
      <>
        <section className='page-header' style={{backgroundImage: `url(${background_image.localFile.childImageSharp.original.src})`}}>
          <div className='page-header__inner'>
          	{ title &&
  	        	<h2 className='page-header__title' dangerouslySetInnerHTML={{ __html: title }} />
  	        }
  	      </div>
          { schedule_a_call &&
            <Link className='page-header__call' to='/contact-us'>
              <h6>Interested in finding out more</h6>
              <span>Schedule a call</span>
            </Link>
          }
        </section>
      </>
    )
  }
}

export default PageHeader