import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class BannerImage extends Component {
  render() {
  	let { image } = this.props
    let contentClass = 'content content--basic-full'
    return (
      <>
        <section className={contentClass}>
          <div className='content__inner'>
            <Fade left distance='30px'>
            	{ image &&
  	        	  <img src={image.localFile.childImageSharp.original.src} alt='Eisen Property' />
    	        }
            </Fade>
  	      </div>
        </section>
      </>
    )
  }
}

export default BannerImage