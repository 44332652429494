import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {
  render() {
    let page = this.props.data.wordpressPage
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} bodyClass={page.slug} description={page.yoast_meta.yoast_wpseo_metadesc} />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage(id: {eq: $id}) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_page_header {
            background_image {
              ...original
            }
            title
            schedule_a_call
          }
          ... on WordPressAcf_contact {
            background_image {
              ...original
            }
            title
          }
          ... on WordPressAcf_basic_content {
            content
            size
          }
          ... on WordPressAcf_two_column_image_content {
            columns: content {
              image {
                ...original
              }
              title
              content
              extended_content
              button {
                link
                text
              }
            }
            padding
          }
          ... on WordPressAcf_video {
            poster {
              ...original
            }
            video
          }
          ... on WordPressAcf_alternating_content {
            alternating: content {
              image {
                ...original
              }
              title
              content
              button {
                link
                text
              }
            }
          }
          ... on WordPressAcf_image_content {
            image {
              ...original
            }
            content
          }
          ... on WordPressAcf_image_content_dark {
            image {
              ...original
            }
            content
          }
          ... on WordPressAcf_banner_image {
            image {
              ...original
            }
          }
          ... on WordPressAcf_services {
            services {
              title
              content
              expanded_content
            }
          }
        }
      }
    }
  }
`
