import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class TwoColumnImageContent extends Component {

  state = {
    toggleActive: [],
  }

  _toggleMore = (e, itemId) => {
    e.preventDefault()
    let { toggleActive } = this.state
    toggleActive[itemId] = !toggleActive[itemId]
    this.setState({ toggleActive })
  }

  render() {
  	let { columns, padding } = this.props
    let { toggleActive } = this.state
    let contentClass = 'content content--columns'
    if ( padding ) 
      contentClass = 'content content--columns content--padding'
    
    return (
      <>
        <section className={contentClass}>
          <div className='content__inner'>
	        	<div className='content__items'>
              { columns &&
                columns.map((el,i) => (
                  <div className='content__item' key={i}>
                    { el.image &&
                      <div className='content__image'>
                        <Fade left distance='30px'>
                          <img src={el.image.localFile.childImageSharp.original.src} alt={el.title} />
                        </Fade>
                      </div>
                    }
                    <Fade bottom distance='30px'>
                      <h4>{el.title}</h4>
                      <div dangerouslySetInnerHTML={{ __html: el.content }} />
                      { el.extended_content &&
                        <>
                        <div className={`expanded ${toggleActive[i] ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: el.extended_content }} />
                        { !toggleActive[i] && <a href='//' onClick={e => this._toggleMore(e, i)}>Read more</a> }
                        { toggleActive[i] && <a href='//' onClick={e => this._toggleMore(e, i)}>Read less</a> }
                        </>
                      }
                      { el.button.link &&
                        <Link className='btn btn--black' to={el.button.link}>{el.button.text}</Link>
                      }
                    </Fade>
                  </div>
                ))
              }
            </div>
  	      </div>
        </section>
      </>
    )
  }
}

export default TwoColumnImageContent