import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Services extends Component {

  static defaultProps = {
    title: '',
    questions: [],
  }

  state = {
    itemActive: [],
    toggleActive: [],
  }

  componentWillMount() {
    let { itemActive } = this.state
    itemActive[0] = true
  }

  _toggleItem = (itemId) => {
    let { itemActive } = this.state
    itemActive.forEach((el, i) => {
      itemActive[i] = false
    });
    itemActive[itemId] = true
    this.setState({ itemActive })
  }

  _toggleMore = (e, itemId) => {
    e.preventDefault()
    let { toggleActive } = this.state
    toggleActive[itemId] = !toggleActive[itemId]
    this.setState({ toggleActive })
  }

  render() {
    let { services } = this.props
    let { itemActive, toggleActive } = this.state
    return (
      <section className='services-block'>
        <Fade bottom distance='30px'>
          <div className='services-block__inner'>
            <div className='services-block__list'>
              {services && services.map((el, i) => (
                <div className={`services-block__item ${itemActive[i] ? 'active' : ''}`} key={i}>
                  <p className='services-block__title' onClick={e => this._toggleItem(i)}>
                    <span className='title'>{el.title}</span>
                  </p>
                  <div className='services-block__item__content'>
                    <div dangerouslySetInnerHTML={{ __html: el.content }} />
                    <div dangerouslySetInnerHTML={{ __html: el.expanded_content }} />
                  </div>
                </div>
              ))}
            </div>
            <div className='services-block__content-wrapper'>
              {services && services.map((el, i) => (
                <div className={`services-block__content ${itemActive[i] ? 'active' : ''}`} key={i}>
                  <div className='default' dangerouslySetInnerHTML={{ __html: el.content }} />
                  { el.expanded_content &&
                    <>
                    <div className={`expanded ${toggleActive[i] ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: el.expanded_content }} />
                    { !toggleActive[i] && <a href='//' onClick={e => this._toggleMore(e, i)}>Read more</a> }
                    { toggleActive[i] && <a href='//' onClick={e => this._toggleMore(e, i)}>Read less</a> }
                    </>
                  }
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </section>
    )
  }
}

export default Services
