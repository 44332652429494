import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import { Play } from '../components/icons'

class Video extends Component {
  state = {
    active: false,
    video: ''
  }

  showVideo = (video) => {
    this.setState({ active: true, video: video }, () => {
      let src = document.querySelector('.content__iframe iframe').getAttribute('src')
      src += '&autoplay=1'
      document.querySelector('.content__iframe iframe').setAttribute('src', src)
    });
  }

  render() {
    const { poster, video } = this.props
    let { active } = this.state

    return (
      <section className='content content--video'>
        <Fade bottom distance='30px'>
          <div className='content__inner'>
            <div className="content__iframe">
              { !active && poster &&
                <picture onClick={() => this.showVideo(video)} >
                  <img src={poster.localFile.childImageSharp.original.src} alt='Eisen Property' />
                  <Play color={'#FFF'} />
                </picture>
              }
              <div dangerouslySetInnerHTML={{__html: video}} />
            </div>
          </div>
        </Fade>
      </section>
    )
  }
}

export default Video
