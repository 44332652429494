import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class BasicContent extends Component {
  render() {
  	let { content, size } = this.props
    let contentClass = 'content content--basic';
    if ( size === 'medium' ) {
      contentClass = 'content content--basic-medium';
    }
    if ( size === 'full' ) {
      contentClass = 'content content--basic-full';
    }
    return (
      <>
        <section className={contentClass}>
          <div className='content__inner'>
            <Fade bottom distance='30px'>
            	{ content &&
  	        	  <div className='content__content' dangerouslySetInnerHTML={{ __html: content }} />
    	        }
            </Fade>
  	      </div>
        </section>
      </>
    )
  }
}

export default BasicContent