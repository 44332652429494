import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class AlternatingContent extends Component {
  render() {
  	let { alternating } = this.props
    return (
      <>
        <section className='content content--alternating'>
          <div className='content__inner'>
	        	<div className='content__items'>
              { alternating &&
                alternating.map((el,i) => (
                  <div className='content__item'>
                    { el.image &&
                      <div className='content__image'>
                        <Fade left distance='40px'>
                          <img src={el.image.localFile.childImageSharp.original.src} alt={el.title} />
                        </Fade>
                      </div>
                    }
                    <div className='content__description'>
                      <Fade bottom distance='30px'>
                        <h4>{el.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: el.content }} />
                        { el.button.link &&
                          <Link className='btn btn--black' to={el.button.link}>{el.button.text}</Link>
                        }
                      </Fade>
                    </div>
                  </div>
                ))
              }
            </div>
  	      </div>
        </section>
      </>
    )
  }
}

export default AlternatingContent