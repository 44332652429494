import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ImageContent extends Component {
  render() {
  	let { image, content } = this.props
    return (
      <>
        <section className='content content--alternating content--ic'>
          <div className='content__inner'>
	        	<div className='content__items'>
              <div className='content__item'>
                { image &&
                  <div className='content__image'>
                    <Fade left distance='30px'>
                      <img src={image.localFile.childImageSharp.original.src} alt='Eisen Property' />
                    </Fade>
                  </div>
                }
                <div className='content__description'>
                  <Fade bottom distance='30px'>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </Fade>
                </div>
              </div>
            </div>
  	      </div>
        </section>
      </>
    )
  }
}

export default ImageContent