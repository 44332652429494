import React from 'react'

import PageHeader from '../components/page-header'
import BasicContent from '../components/basic-content'
import TwoColumnImageContent from '../components/two-column-image-content'
import Video from '../components/video'
import AlternatingContent from '../components/alternating-content'
import ImageContent from '../components/image-content'
import ImageContentDark from '../components/image-content-dark'
import BannerImage from '../components/banner-image'
import Services from '../components/services'
import Contact from '../components/contact'

const renderBlock = (param, el, i, page={}) => {
  let block = {
    'WordPressAcf_page_header' : (el, i) => <PageHeader key={i} {...el} />,
    'WordPressAcf_basic_content' : (el, i) => <BasicContent key={i} {...el} />,
    'WordPressAcf_two_column_image_content' : (el, i) => <TwoColumnImageContent key={i} {...el} />,
    'WordPressAcf_video' : (el, i) => <Video key={i} {...el} />,
    'WordPressAcf_alternating_content' : (el, i) => <AlternatingContent key={i} {...el} />,
    'WordPressAcf_image_content' : (el, i) => <ImageContent key={i} {...el} />,
    'WordPressAcf_image_content_dark' : (el, i) => <ImageContentDark key={i} {...el} />,
    'WordPressAcf_banner_image' : (el, i) => <BannerImage key={i} {...el} />,
    'WordPressAcf_services' : (el, i) => <Services key={i} {...el} />,
    'WordPressAcf_contact' : (el, i) => <Contact key={i} {...el} />,
  }[param]

  if (!block) return


  return block(el, i)
}

export default renderBlock
