import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    enquiry: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<p className='contact__success'>Thanks for getting in contact with us.<br /> A member of our team will be in touch soon.</p>)

    return (
      <form {...props}>
        <div className='form__row'>
          <h4>Interested in finding out more</h4>
        </div>
        <div className='form__row'>
          <input type='text' name='name' placeholder='Your name' onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input type='email' name='email' placeholder='Email' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <input type='text' name='phone' placeholder='Contact number' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <input type='text' name='phone' placeholder='Company' onChange={this.handleChange}  />
        </div>
        <div className='form__row'>
          <textarea name='enquiry' placeholder='Enquiry' onChange={this.handleChange} />
        </div>
        <div className='form__row form__row--submit'>
          <button type='submit' className='btn btn--white'>Send Enquiry</button>
        </div>
      </form>
    )
  }
}

class Contact extends Component {
  render() {
  	let { title, background_image } = this.props
    return (
      <>
        <section className='page-header contact' style={{backgroundImage: `url(${background_image.localFile.childImageSharp.original.src})`}}>
          <div className='page-header__inner'>
          	{ title &&
  	        	<h2 className='page-header__title' dangerouslySetInnerHTML={{ __html: title }} />
  	        }
  	      </div>
          <div className="contact__form">
            <Form />
          </div>
        </section>
      </>
    )
  }
}

export default Contact